/**
 * 
 *  RWD
 *  css/partials/_rwd.scss
 *
 */

@media screen and (min-width: 1200px) {
    .col-lg-1-quarter {
        float: left;
        width: 10.41666666666667%;
        position: relative;
        min-height: 1px;
    }
    .col-lg-1-half {
        float: left;
        width: 12.5%;
        position: relative;
        min-height: 1px;
    }
    .col-lg-1-three-fourth {
        float: left;
        width: 14.58333333333333%;
        position: relative;
        min-height: 1px;
    }
    .col-lg-2-half {
        float: left;
        width: 20.83333333333333%;
        position: relative;
        min-height: 1px;
    }
    .col-lg-one-fifth {
        float: left;
        width: 20%;
        position: relative;
        min-height: 1px;
    }
    .homepage-stock-filters .filter-options {
        display: block !important;
    }
}

@media screen and (min-width: 1476px) {
    .container.wider {
        width: 1446px;
    }
    .col-xl-offset-0 {
        margin-left: 0;
    }
    .col-xl-offset-1 {
        margin-left: 8.33333333%;
    }
    .col-xl-offset-2 {
        margin-left: 16.66666667%;
    }
    .col-xl-1-quarter {
        float: left;
        width: 10.41666666666667%;
        position: relative;
        min-height: 1px;
    }
    .col-xl-1-half {
        float: left;
        width: 12.5%;
        position: relative;
        min-height: 1px;
    }
    .col-xl-2-half {
        float: left;
        width: 20.83333333333333%;
        position: relative;
        min-height: 1px;
    }
    .col-xl-one-fifth {
        float: left;
        width: 20%;
        position: relative;
        min-height: 1px;
    }
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11 {
        float: left;
    }

    .col-xl-1 {
        width: 8.33333%;
    }

    .col-xl-2 {
        width: 16.66667%;
    }

    .col-xl-3 {
        width: 25%;
    }

    .col-xl-4 {
        width: 33.33333%;
    }

    .col-xl-5 {
        width: 41.66667%;
    }

    .col-xl-6 {
        width: 50%;
    }

    .col-xl-7 {
        width: 58.33333%;
    }

    .col-xl-8 {
        width: 66.66667%;
    }

    .col-xl-9 {
        width: 75%;
    }

    .col-xl-10 {
        width: 83.33333%;
    }

    .col-xl-11 {
        width: 91.66667%;
    }

    .col-xl-12 {
        width: 100%;
    }

    .homepage {
        &-aboutus {
            &-asset {
                i {
                    font-size: 120px;
                }

                .number {
                    font-size: 35px;
                }

                .text {
                    font-size: 16px;
                }
            }
        }

        &-stock {
            &-filters {
                .filter {
                    &-title {
                        font-size: 13px;
                    }
                }
            }
        }

        &-contact {
            &-box {
                .phonelink {
                    font-size: 28px;
                }

                .medialink {
                    margin-left: 30px;
                }
            }
        }
    }

    .cart {
        &-products {
            .video {
                &-price {
                    font-size: 13px;
                    margin-top: 7px;
                }

                &-comment {
                    font-size: 13px;
                }

                &-buttons {
                    a, button {
                        height: 30px;
                        font-size: 13px;
                        margin-right: 6px;
                        padding: 7px 0;
                        width: 30px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1475px) {
    .homepage {
        &-aboutus {
            &-asset {
                i {
                    font-size: 120px;
                }

                .number {
                    font-size: 35px;
                }

                .text {
                    font-size: 16px;
                }
            }
        }

        &-stock {
            &-filters {
                .filter {
                    &-title {
                        font-size: 13px;
                    }
                }
            }
        }

        &-contact {
            &-box {
                .phonelink {
                    font-size: 28px;
                }

                .medialink {
                    margin-left: 30px;
                }
            }
        }
    }

    .cart {
        &-products {
            .video {
                &-price {
                    font-size: 13px;
                    margin-top: 7px;
                }

                &-comment {
                    font-size: 13px;
                }

                &-buttons {
                    a, button {
                        height: 30px;
                        font-size: 13px;
                        margin-right: 6px;
                        padding: 7px 0;
                        width: 30px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .homepage {
        &-aboutus {
            &-text {
                margin-bottom: 55px;
            }

            &-asset {
                margin-bottom: 75px;

                i {
                    font-size: 100px;
                }

                .number {
                    font-size: 30px;
                }

                .text {
                    font-size: 16px;
                    margin-left: 10px;
                }
            }
        }

        &-offer {
            &-image {
                height: 337px !important;
            }
        }

        &-stock {
            &-filters {
                .filter {
                    &-options {
                        display: none;
                    }
                }
            }

            &-categories {
                .categorybox.bigger {
                    .video {
                        &-title {
                            font-size: 16px;
                        }

                        &-details {
                            i {
                                font-size: 16px;
                            }
                        }

                        &-time {
                            margin-right: 5px;
                        }

                        &-time-value, &-resolution-value {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        &-realizations {
            &-logos {
                &-carousel {
                    //padding: 0 15px;
                }
            }

            &-map {
                height: 450px;
            }
        }

        &-contact {
            &-box {
                .icon-telefon {
                    font-size: 32px;
                }

                .phonelinks {
                    margin-left: 16px;
                }

                .phonelink {
                    font-size: 24px;
                }

                .icon-email {
                    font-size: 32px;
                }

                .emaillink {
                    font-size: 20px;
                    margin-left: 16px;
                }

                .medialink {
                    margin-left: 15px;
                }
            }
        }
    }

    .video {
        &-frame {
            .title {
                font-size: 30px;
            }

            .resolutions {
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 991px) {
    .global {
        &-title {
            font-size: 32px;
            max-width: calc(100% - 120px);

            &:before, &:after {
                width: 60px;
            }
        }
    }

    .hamburger {
        display: inline-block;
    }

    .header {
        background-color: $black;
        height: 66px !important;

        &-blackbackgroundsubpage {
            height: 66px !important;
        }

        &-logo {
            height: 66px !important;

            &-image {
                height: 50px !important;
            }
        }

        &-menu {
            &-desktop {
                display: none;
            }
        }

        &-languages {
            display: none;
        }
    }

    .aside {
        &.active {
            transform: translateX(230px);
            transition: transform .6s ease .1s;
        }
    }

    .subpage {
        &-carousel {
            i {
                font-size: 50px;
            }

            &-controls {
                .owl-prev {
                    i {
                        padding-right: 20px;

                        &:hover {
                            padding-left: 25px;
                        }
                    }
                }

                .owl-next {
                    i {
                        padding-left: 20px;

                        &:hover {
                            padding-right: 25px;
                        }
                    }
                }

                i {
                    font-size: 14px;
                    padding: 12.5px 11px;
                }
            }
        }

        &-spacer {
            height: 66px !important;
        }

        &-margin {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    .homepage {
        &-header {
            &-bottom {
                bottom: 70px;
            }
        }

        &-aboutus {
            &-text {
                margin-bottom: 50px;
            }

            &-asset {
                margin-bottom: 50px;

                i {
                    font-size: 75px;
                }

                .number {
                    font-size: 22px;
                }

                .text {
                    font-size: 12px;
                    margin-left: 5px;
                }
            }
        }

        &-offer {
            &-background, &-background2 {
                height: 256px !important;
            }

            &-image {
                //height: 300px !important;
                max-height: 200px;
            }

            &-section {
                &.darkerbackground {
                    .homepage-offer-box .text, .homepage-offer-section.darkerbackground .homepage-offer-box .bottombutton {
                        padding: 0;
                    }
                }
            }

            &-box {
                padding-bottom: 0;

                .title {
                    font-size: 28px;
                }

                .title, .text, .bottombutton {
                    padding: 0;
                }

                .bottombutton {
                    position: relative;
                    bottom: auto;
                }
            }
        }

        &-stock {
            &-background {
                background-position-x: 75% !important;
                height: 200px !important;
            }

            &-filters {
                z-index: 10;
                &.fixed {
                    &.lowertop {
                        top: 66px;
                    }
                }
            }

            &-categories {
                .video {
                    &-title {
                        text-align: left;
                    }

                    &-button {
                        float: right;
                    }

                    &-details {
                        text-align: left;
                        width: 100%;
                    }
                }
            }
        }

        &-contact {
            padding-top: 20px;

            &-box {
                height: 108px;

                &:before, &:after {
                    display: none;
                }

                .centering {
                    text-align: center;
                }

                .medialink {
                    margin: 0 15px 20px;
                }
            }
        }
    }

    .video {
        &-frame {
            .iframecontent {
                padding: 30px;
                padding-bottom: 15px;
            }

            .content {
                padding: 30px;
                padding-top: 15px;
            }

            .tags {
                border-bottom: 1px solid $grey;
                padding-left: 0;
            }
        }
    }

    .homepage-stock-filters.fixed{
        padding: 10px 0 0;
    }
    .homepage-stock-categories.extrapadding{
        padding-top: 90px;
    }
}

@media screen and (max-width: 991px) and (max-height: 500px) {
    .footer {
        &-button {
            &.fixed {
                display: none !important;
            }
        }
    }

    .homepage-stock-filters .toggle-filters, .homepage-stock-filters .bootstrap-select, .homepage-stock-filters .filter-input, .homepage-stock-filters .filter-fullwidthbutton {
        margin-bottom: 10px;
    }

    .homepage-stock-filters.fixed {
        max-height: calc(100% - 66px);
    }

    //FILTROWANIE MOBILE
    /*
    .homepage-stock-categories.extrapadding {
        padding-top: 25px;
    }

    .homepage-stock-filters .toggle-filters {
        display: none;

        &.open {
            display: inline-block;
        }
    }

    .homepage-stock-filters.fixed {
        height: calc(100% - 66px);
        opacity: 0;
        pointer-events: none;
        transition: opacity .3s ease;

        &.open {
            opacity: 1;
            pointer-events: all;
        }

        .container{
            padding-bottom: 10px;
        }
    }


    .global-toggle-fixed {
        display: inline-block;

        &.open {
            opacity: 0;
            pointer-events: none;
        }
    }
    */
}

@media screen and (max-width: 767px) {
    .global {
        &-title {
            padding: 25px 20px;
        }
    }

    .homepage-stock-filters .toggle-filters, .homepage-stock-filters .bootstrap-select, .homepage-stock-filters .filter-input, .homepage-stock-filters .filter-fullwidthbutton {
        margin-bottom: 10px;
    }

    .homepage-stock-filters.fixed {
        max-height: calc(100% - 66px);
    }

    //FILTROWANIE MOBILE
    /*
    .homepage-stock-categories.extrapadding {
        padding-top: 25px;
    }

    .homepage-stock-filters .toggle-filters {
        display: none;

        &.open {
            display: inline-block;
        }
    }

    .homepage-stock-filters.fixed {
        height: calc(100% - 66px);
        opacity: 0;
        pointer-events: none;
        transition: opacity .3s ease;

        &.open {
            opacity: 1;
            pointer-events: all;
        }
        .container{
            padding-bottom: 10px;
        }
    }

    .global-toggle-fixed {
        display: inline-block;

        &.open {
            opacity: 0;
            pointer-events: none;
        }
    }
    */

    .footer {
        &-button {
            &.fixed {
                display: none !important;
            }
        }
    }

    .cart {
        &-header {
            &-title {
                padding-bottom: 22.5px;
                text-align: center;
                width: 100%;
            }

            &-nav {
                padding-bottom: 22.5px;
                padding-top: 22.5px;
                text-align: center;
            }
        }

        &-products {
            .video {
                &-title {
                    font-size: 11px;
                }

                &-details {
                    i {
                        font-size: 11px;
                        margin-right: 2px;
                        position: relative;
                        top: 1px;
                    }
                }

                &-time {
                    margin-right: 5px;
                }

                &-time, &-resolution {
                    &-value {
                        font-size: 11px;
                    }
                }

                &-buttons {
                    a, button {
                        height: 20px;
                        font-size: 11px;
                        margin-right: 1px;
                        padding: 3px 0;
                        width: 20px;
                    }
                }

                &-price {
                    font-size: 11px;
                    margin-top: 3px;
                }

                &-comment {
                    font-size: 11px;
                }
            }
        }
    }

    .homepage {
        &-aboutus {
            &-asset {
                i {
                    font-size: 55px;
                }

                .number {
                    font-size: 17px;
                }

                .text {
                    font-size: 12px;
                    margin-left: 5px;
                    width: calc(100% - 65px);
                }
            }
        }

        &-offer {
            &-section {
                padding-top: 50px;
                padding-bottom: 50px;
            }

            &-box {
                .title {
                    font-size: 22px;
                    margin-bottom: 15px;
                }

                .text {
                    p, ul {
                        margin-bottom: 15px;
                    }
                }
            }

            &-image {
                max-height: 188px;
            }
        }

        &-stock {
            &-categories {
                .categorybox.bigger {
                    padding-left: 15px;
                    padding-right: 15px;

                    .video {
                        &-box {
                            padding-left: 10px;
                            padding-right: 10px;
                            width: 25%;
                        }

                        &-title {
                            font-size: 12px;
                        }

                        &-details {
                            i {
                                font-size: 15px;
                            }
                        }

                        &-time {
                            margin-right: 3px;
                        }

                        &-time-value, &-resolution-value {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        &-realizations {
            &-carousel {
                i {
                    font-size: 50px;
                }

                .caption {
                    font-size: 13px;
                    height: 50px;
                }
            }

            &-controls {
                .owl-prev {
                    i {
                        padding-right: 20px;

                        &:hover {
                            padding-left: 25px;
                        }
                    }
                }

                .owl-next {
                    i {
                        padding-left: 20px;

                        &:hover {
                            padding-right: 25px;
                        }
                    }
                }

                i {
                    font-size: 14px;
                    padding: 12.5px 11px;
                }
            }

            &-map {
                height: 220px;

                .caption {
                    background-color: rgba($white, 0.3);
                }
            }
        }
    }
}

@media screen and (max-width: 639px) {
    .homepage {
        &-stock {
            &-categories {
                .title {
                    font-size: 20px;
                }

                .categorybox.bigger {
                    padding-left: 15px;
                    padding-right: 15px;

                    .video {
                        &-box {
                            margin-top: 15px;
                            margin-bottom: 15px;
                        }

                        &-image {
                            margin-bottom: 10px;
                        }

                        &-title {
                            font-size: 14px;
                        }

                        &-details {
                            i {
                                font-size: 14px;
                            }
                        }

                        &-time {
                            margin-right: 5px;
                        }

                        &-time-value, &-resolution-value {
                            font-size: 14px;
                        }
                    }
                }

                .video {
                    &-image {
                        .ratio {
                            padding-bottom: 62.5%;
                        }
                    }
                }
            }
        }
    }

    .video {
        &-frame {
            .title {
                font-size: 26px;
            }

            .description {
                font-size: 18px;
            }

            .tags {
                font-size: 16px;
            }

            .details {
                &-column {
                    font-size: 16px;
                    margin-bottom: 7.5px;
                }
            }

            .orderbutton {
                margin-top: 20px;
            }

            .resolutions {
                border: 0;
                width: 100%;

                tr td, tr th {
                    border: inherit;
                    padding: inherit;
                }

                thead {
                    display: none;
                }

                tbody {
                    tr {
                        border: 1px solid $blue2;
                        margin-bottom: 40px;
                        display: block;

                        &:after {
                            content: "";
                            display: table;
                            clear: both;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    td {
                        display: block;
                        float: left;
                        clear: left;
                        width: 100% !important;
                        text-align: right !important;
                        font-size: 13px;
                        height: 40px;
                        line-height: 40px;
                        padding-right: 10px;

                        &:last-child {
                            height: auto;
                            line-height: normal;
                            min-height: 60px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            padding-left: 10px;
                            text-align: center !important;

                            &:before {
                                display: none;
                            }
                        }

                        &:before {
                            background-color: $blue2;
                            color: $white;
                            content: attr(data-label);
                            float: left;
                            padding-left: 10px;
                            text-align: left;
                            text-transform: uppercase;
                            width: 50%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 567px) {
    .col-portrait-6 {
        width: 50%;
    }
    .col-portrait-12 {
        left: 0;
        width: 100%;
    }
    .homepage {
        &-offer {
            &-image {
                max-height: 155px;
            }
        }

        &-stock {
            &-categories {
                .categorybox.bigger {
                    .video {
                        &-box {
                            width: 50%;
                        }
                    }
                }

                .video {
                    &-time, &-resolution {
                        padding: 2px 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .col-ms-offset-0 {
        margin-left: 0;
    }

    .col-ms-12 {
        left: 0;
    }

    .cart {
        &-header {
            &-nav {
                .action {
                    border-right: 0;
                    //display: block;
                    padding: 0 5px !important;
                    font-size: 15px;
                }
            }
        }

        &-options {
            .bordercolumn {
                &:before {
                    //display: none;
                }
            }
        }
    }

    .global {
        &-title {
            font-size: 26px;
            max-width: calc(100% - 50px);

            &:before, &:after {
                width: 25px;
            }
        }
    }

    .footer {
        font-size: 14px;
    }

    .homepage {
        &-aboutus {
            &-text {
                margin-bottom: 25px;
            }

            &-asset {
                margin-bottom: 25px;

                i {
                    font-size: 100px;
                }

                .number {
                    font-size: 30px;
                }

                .text {
                    font-size: 16px;
                    margin-left: 10px;
                    width: calc(100% - 115px);
                }
            }
        }

        &-offer {
            &-image {
                height: 215px !important;
                max-height: none;
            }

            &-box {
                .title {
                    font-size: 26px;

                    &.rwd-margin {
                        margin-top: 35px;
                    }
                }
            }
        }

        &-realizations {
            &-carousel {
                i {
                    font-size: 55px;
                }
            }

            &-controls {
                i {
                    font-size: 14px;
                    padding: 15.5px 20px;
                }

                .owl-prev {
                    i {
                        padding-right: 35px;
                    }
                }

                .owl-next {
                    i {
                        padding-left: 35px;
                    }
                }
            }

            &-map {
                .caption {
                    font-size: 20px;
                }
            }
        }

        &-contact {
            &-box {
                height: auto;

                .centering {
                    left: auto;
                    padding: 20px 0;
                    position: relative;
                    top: 0;
                    text-align: center;
                    transform: none;
                    width: auto;
                }

                .medialink {
                    margin: 0 5px 20px;
                }
            }
        }
    }

    .subpage {
        &-carousel {
            i {
                font-size: 55px;
            }

            &-controls {
                i {
                    font-size: 14px;
                    padding: 15.5px 20px;
                }

                .owl-prev {
                    i {
                        padding-right: 35px;
                    }
                }

                .owl-next {
                    i {
                        padding-left: 35px;
                    }
                }
            }
        }
    }

    .video {
        &-frame {
            .iframecontent {
                padding: 15px;
                padding-bottom: 7.5px;
            }

            .content {
                padding: 15px;
                padding-bottom: 7.5px;
                padding-top: 7.5px;
            }

            .similarvideos {
                padding: 15px;
                padding-bottom: 7.5px;
                padding-top: 7.5px;
            }

            .backwardscontent {
                padding: 15px;
                padding-top: 7.5px;
                padding-bottom: 15px;
            }
        }
    }
}

@media screen and (max-width: 380px) {
    .col-gs-12 {
        width: 100%;
    }

    .cart {
        &-header {
            &-nav {
                padding-top: 0;
                padding-bottom: 10px;

                .action {
                    font-size: 13px;
                    padding: 0 3px !important;
                    height: auto;
                }
            }
        }

        &-options {
            .optionbutton {
                font-size: 12px;
                margin-bottom: 5px;
            }
        }

        &-products {
            .row {
                margin-left: -5px;
                margin-right: -5px;
            }

            .categorycolumn {
                margin-bottom: 10px;
                padding-left: 5px;
                padding-right: 5px;
            }

            .categorybox {
                padding-left: 7px;
                padding-right: 7px;
            }

            .video {
                &-box {
                    margin-bottom: 3px;
                    margin-top: 10px;
                }
            }
        }
    }

    .homepage {
        &-stock {
            &-searchform {
                input {
                    font-size: 13px;
                    padding-left: 50px;
                    padding-right: 105px;
                }

                button {
                    font-size: 13px;
                    width: 90px;
                }
            }
        }
    }
}

//ZAKRES 640PX - 767PX DO DEVELOPERS TOOLS W CHROME

@media screen and (max-width: 767px) and (min-width: 640px) {
    .pod-chrome {
        display: none;
    }
}

@media screen and (max-width: 639px) and (min-width: 568px) {
    .pod-chrome {
        display: none;
    }
}

@media screen and (max-width: 567px) and (min-width: 480px) {
    .pod-chrome {
        display: none;
    }
}